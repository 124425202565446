.select-call-extension.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px !important;
  line-height: 30px !important;
}

.select-call-extension.ant-select-dropdown {
  border-radius: 6px !important;
}

.select-call-extension .ant-select-selection-placeholder {
  color:  #8A94A8 !important;
  font-size: 14px;
}

.call-extension-switch {
  width: 34px;
  height: 19px;
  background-color: #D0D5DD;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  align-self: center;
}

.call-extension-switch-handle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
  align-self: center;
  transform: translateX(2px) translateY(1.5px);
}

.call-extension-switch-handle.checked {
  background-color: white;
}

.call-extension-switch.checked {
  background-color: #825AC7;
}

.call-extension-switch.checked .call-extension-switch-handle {
  transform: translateX(16px) translateY(1.5px);
}

.select-call-extension .ant-select-item-option:hover,
.ant-select-item-option:active,
.ant-select-item-option:focus {
  background-color: transparent !important;
  color: inherit !important;
}

.select-call-extension .ant-select-item {
  padding: 0px;
  margin: 2px 0px 2px 0px;
}

.required-dot {
  width: 3px;
  height: 3px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
  margin-top: 2px;
}

.disabled-row {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #ffffff;
}
.disabled-row:hover {
  cursor: not-allowed;
}
.delete-extension .ant-modal-header {
  padding: 0;
}

.delete-extension .ant-modal-body {
  padding: 0 !important;
}

.delete-extension .ant-modal-footer {
  padding: 0 !important;
}

.delete-extension .ant-modal-content {
  padding: 20px !important;
}