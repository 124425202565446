.widget-title-container {
  margin: 24px 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.widget-title-container .widget-title-style {
  color: #3A485F;
  font-family: Inter_18pt-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.widget-title-container .widget-date-style  {
  color: #8A94A8;
  font-family: Inter_18pt-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
