.ant-radio-checked .ant-radio-inner{
  border-color: #825ac7 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #825ac7;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #825ac7 ;
}