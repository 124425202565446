.custom-tab-style {
  .ant-tabs-nav {
    background-color: transparent !important;
    padding-left: 5px !important;
    margin-left: -24px;
  }

  .ant-tabs-ink-bar {
    background: rgb(130, 90, 199) !important;
    height: 3px !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 0px;
  }

  .ant-tabs-tab {
    font-size: 16px;
    margin: 0 0 0 12px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgb(130, 90, 199) !important;

    :hover {
      color: rgb(130, 90, 199) !important;

    }
  }
  .ant-tabs-tab:hover {
    color: rgb(130, 90, 199) !important;
  }
}

.custom-menu{
  border-radius: 7px !important;
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
    background-color: rgb(130, 90, 199,0.1) !important;
    color: rgb(130, 90, 199) !important;
  }
}


.order-timeline .ant-timeline-item {
  padding-bottom: 0 !important;
}

.order-timeline .ant-timeline-item-tail {
  top: 10px;
  height: 110% !important;
}

.order-timeline .ant-timeline-item-head {
  background: transparent !important;
}

.order-month-text {
  margin-top: 10px;
  /*  */
  writing-mode: vertical-lr;
  transform: scale(-1);
  display: flex;
  flex-direction: row;
  padding-right: 0 !important;
  justify-content: flex-end;
  align-items: center;
}
