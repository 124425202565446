.popGroupFormNotificationConfirmation .ant-modal-content .ant-modal-header {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.popGroupFormNotificationConfirmation .ant-modal-content .ant-modal-header .ant-modal-title {
    font-weight: 600;
    font-family: 'Manrope-Medium';
}

.popGroupFormNotificationConfirmation .ant-modal-content .ant-modal-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}