.convert-patient-custom-switch {
  width: 34px;
  height: 19px;
  background-color: #D0D5DD;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  align-self: center;
}

.convert-patient-custom-switch-handle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
  align-self: center;
  transform: translateX(2px) translateY(1.5px);
}

.convert-patient-custom-switch-handle.checked {
  background-color: white;
}

.convert-patient-custom-switch.checked {
  background-color: #825AC7;
}

.convert-patient-custom-switch.checked .convert-patient-custom-switch-handle {
  transform: translateX(16px) translateY(1.5px);
}