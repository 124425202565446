.tooltip-title-custom {
  color: #98A2B3;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding: 14px 16px 4px 14px;
}
.border-bottom {
  border-bottom-width: 1px;
  border-bottom-color: #D0D5DD;
  border-bottom-style: solid;
}
.text-desc {
color: #475467;
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 21px */
padding: 4px 16px 0px 14px;
white-space: pre-wrap;
}
.ER-tooltip .ant-tooltip-arrow-content::before {
background-color: #fff !important;
background: #fff !important;
}
.ER-tooltip .ant-tooltip-placement-bottom {
  background-color: transparent !important;
}
.ant-tooltip {
  z-index: 99999;
}
