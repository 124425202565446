@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Montserrat:wght@300;400;500;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Inter_18pt-Regular';
  font-smooth: antialiased;
}

.btnTagDefault {
  background-color: #f2f4f7 !important;
  border-color: #f2f4f7 !important;
  color: #344054 !important;
  font-weight: 500 !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  /* background: #825ac7; */
  border-radius: 10px;
}

/* ant-btn */
.ant-btn {
  border-radius: 180px !important;
}
.ant-btn-primary {
  color: #fff;
  border-color: #825ac7 !important;
  background: #825ac7 !important;
}
.ant-checkbox {
  border-radius: 6px;
}
.ant-checkbox-input {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #825ac7 !important;
  border-color: #825ac7 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #825ac7 !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #825ac7 !important;
}
.ant-modal-content {
  border-radius: 16px !important;
}

.automationModalContainer .ant-modal-body {
  padding-top: 0px !important;
}

.automationModalContainer .ant-modal-header {
  padding-bottom: 0px !important;
}

.automationModalContainer .ant-card-body {
  padding: 0px !important;
}

.automationModalContainer th.ant-table-cell:first-child {
  padding-left: 2rem !important;
}

.automationModalContainer td.ant-table-cell:first-child {
  padding-left: 2rem !important;
}

.automationModalContainer th.ant-table-cell:last-child {
  padding-right: 2rem !important;
}

.automationModalContainer td.ant-table-cell:last-child {
  padding-right: 2rem !important;
}

.automationModalContainer .ant-pagination::after {
  content: unset !important;
}

.customAutomationInput {
  border-radius: 8px !important;
}

.customAutomationSelect.ant-select .ant-select-selector {
  border-radius: 8px !important;
}

.automationModalContainer .ant-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 2rem;
}

.ant-modal-body {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.ant-modal-header {
  border-bottom: none !important;
  border-radius: 16px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.ant-modal-footer {
  border-top: none !important;
  padding-bottom: 32px !important;
  padding-right: 32px !important;
}

.ant-dropdown-trigger , .ant-dropdown-trigger.ant-table-filter-trigger.active , .ant-table-column-sorters .ant-table-column-sorter-inner .anticon.active {
  color: #825ac7 !important;
}
.ant-dropdown-trigger.ant-table-filter-trigger , .ant-table-column-sorters .ant-table-column-sorter-inner .anticon  {
  color: #bfbfbf !important;
}
.ant-spin-dot-item {
  background-color: #825ac7 !important;
}
/*  */

.opportunityCreateModalInput {
  border-radius: 8px;
  padding: 8px;
}

.ant-card.ant-card-bordered {
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border: 1px solid #e4e7ec;
}

.recharts-legend-item {
  display: inline-flex !important;
  justify-content: flex-start !important;
  align-items: baseline !important;
}

.analyticsViewOpportunitiesContainer .equal-height .ant-row,
.analyticsViewOpportunitiesContainer .equal-height .ant-card {
  height: 100%;
}

/* .analyticsViewOpportunitiesContainer .ant-row {
  height: 100%;
} */

.analyticsViewOpportunitiesContainer h1 {
  font-size: 30px;
  color: #101828;
}

.analyticsViewOpportunitiesContainer h2,
.ant-card-head-title {
  font-size: 18px;
  color: #101828;
}

.analyticsViewOpportunitiesContainer .gutter-row .ant-tag {
  border-radius: 16px;
}

.analyticsViewOpportunitiesContainer .gutter-row .ant-card-body {
  border-top: none !important;
  padding: 0 24px !important;
}

.analyticsViewOpportunitiesContainer .gutter-row .ant-card-head {
  border-bottom: none !important;
}

.analyticsViewOpportunitiesContainer .gutter-row:last-child {
  padding-right: 0px !important;
}
.analyticsViewOpportunitiesContainer .gutter-row:first-child {
  padding-left: 0px !important;
}
.analyticsViewOpportunitiesContainer .gutter-row {
  padding: 0px 10px;
}

.analyticsViewOpportunitiesContainer .recharts-bar .recharts-rectangle {
  max-width: 96px !important;
}

.recharts-legend-icon {
  border-radius: 100% !important;
}

.primaryFilledBtn {
  color: white !important;
  background-color: #825ac7;
  border-color: #825ac7;
  font-size: 0.9rem !important;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal .primaryFilledBtn {
  border-radius: 60px;
  background-color: #f9f5ff;
  border-color: #f9f5ff;
  color: #825ac7 !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  margin-left: 2rem !important;
}

.ant-modal .primaryFilledBtn:hover {
  background-color: #825ac7;
  border-color: #825ac7;
  color: #fff !important;
}

.ant-modal .secondaryFilledBtn {
  border-radius: 60px !important;
}

.analyticsOpportunitiesContainer .primaryFilledBtn:hover {
  background-color: #825ac7;
  border-color: #825ac7;
}

.primarySmoothBtn {
  background-color: #f9f5ff;
  border-color: #f9f5ff;
  color: #6941c6 !important;
  font-size: 0.9rem !important;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.primarySmoothBtn:hover {
  background-color: #f9f5ff;
  border-color: #f9f5ff;
  color: #6941c6 !important;
  font-size: 0.9rem !important;
  font-weight: 500;
}

.analyticsOpportunitiesContainer .ant-btn.ant-btn-default.ant-btn-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f0f9ff;
  border-color: transparent;
  color: #0369a1;
}

/* .analyticsOpportunitiesContainer .ant-btn.ant-btn-default.ant-btn-sm:hover {
  border-color: #825ac7;
  color: #825ac7;
}

.analyticsOpportunitiesContainer .ant-btn.ant-btn-default.ant-btn-sm:hover svg {
  fill: #825ac7;
} */

.analyticsOpportunitiesContainer .paginationNext,
.analyticsOpportunitiesContainer .paginationPrev,
.analyticsViewOpportunitiesContainer .paginationNext,
.analyticsViewOpportunitiesContainer .paginationPrev {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
}

.analyticsOpportunitiesContainer .paginationNext,
.analyticsViewOpportunitiesContainer .paginationNext {
  right: 24px;
}

.analyticsOpportunitiesContainer .paginationPrev,
.analyticsViewOpportunitiesContainer .paginationPrev {
  left: 24px;
}

.analyticsOpportunitiesContainer .ant-pagination,
.analyticsViewOpportunitiesContainer .ant-pagination {
  padding-top: 16px;
  justify-content: center;
  position: relative;
}

.analyticsOpportunitiesContainer .ant-pagination-item,
.analyticsViewOpportunitiesContainer .ant-pagination-item {
  border-radius: 8px !important;
  border: none;
  color: #667085;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.analyticsOpportunitiesContainer
  .ant-pagination-item.ant-pagination-item-active {
  background-color: #f9f5ff;
}

.analyticsOpportunitiesContainer
  .ant-pagination-item.ant-pagination-item-active
  a {
  color: #825ac7 !important;
}

.analyticsOpportunitiesContainer .ant-table-cell.ant-table-selection-column {
  padding-left: 1.5rem !important;
}
.analyticsOpportunitiesContainer
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td {
  background-color: #f9f5ff;
}

.graph-member-list-drawer 
.ant-table-tbody
> tr.ant-table-row-selected
> td {
  background-color: white;
}
.analyticsOpportunitiesContainer .ant-table-thead > tr > th {
  padding: 8px !important;
  color: #667085;
}

.analyticsOpportunitiesContainer .btnFavorite svg,
.analyticsOpportunitiesContainer .btnMoreOptions svg {
  fill: #667085;
}

.analyticsOpportunitiesContainer .ant-table-tbody > tr > td {
  padding: 28px 8px !important;
  font-weight: 500;
  color: #667085;
}

.analyticsOpportunitiesContainer .ant-table-tbody > tr > td:nth-child(2) {
  color: #101828 !important;
}

.analyticsOpportunitiesContainer
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: unset !important;
}

.oppsTableInputSearchContainer .ant-input-affix-wrapper {
  border-radius: 0.55rem;
}

.analyticsOpportunitiesContainer .ant-card.ant-card-bordered {
  border-radius: 0.55rem !important;
}

.analyticsOpportunitiesContainer .ant-card-body {
  border-top: 1px solid #eee;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* .ant-table-row-selected {
  background-color: #f9f5ff;
} */
.ant-table-thead > tr > th {
  color: #667085;
  background: #f9fafb !important;
}

td.ant-table-column-sort {
  background: #ffffff;
}