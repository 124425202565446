.widgetBoxShadow {
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
}

.graph-wrapper {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

/* canvas {

  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
} */


.print-renderer {
  display: none;
}

.renderer {
  display: block;
}

.header,
.header-space,
.footer,
.footer-space {
  height: 200px;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
}

.header,
.header-space,
.footer,
.footer-space {
  display: none;
}

.section-title-text {
  font-size: 16px;
  font-weight: 500;
  color: #3A485F;
  font-family: 'Inter_18pt-Medium';
}

.section-subtitle-title-text {
  font-size: 14px;
  font-weight: 300;
  color: #8A94A8;
  font-family: 'Inter_18pt-Regular';
}

.widgetBoxShadow {
  /* box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  -webkit-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06); */
  border: 1px solid #F2F4F7;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 24px 0px rgba(0, 0, 0, 0.02);
}

.common-style {
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0px;
  position: relative;
  z-index: 0;
}

.card-widget-wrapper {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
}

.card-widget-type {
  background-color: rgb(255, 255, 255);
}

.card-widget-type .percent-bg {
  display: flex;
  min-width: 54px;
  height: 22px;
  padding-left: 4px;
  padding-right: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: rgb(244, 204, 201);
}

.card-widget-type-1 {
  width: 550px;
}

.card-widget-type-1 .widgetBoxShadow {
  padding: 15px 26px 15px 24px;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 24px;
  min-height: 140px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.card-widget-type-2 {
  width: 385px;
}

.card-widget-type-2 .widgetBoxShadow {
  display: flex;
  align-items: center;
  padding: 15px 26px 15px 23px;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 154px;
  background-color: #fff;
}

.widget-wrapper-3 {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
}

.widget-type-3 {
  background-color: rgb(255, 255, 255);
  width: 550px;
}

.widget-type-3 .widgetBoxShadow {
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}



.widget-wrapper-5 {
  display: flex;
  /* justify-content: space-between; */
}

.widget-wrapper-4 {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
}

.widget-type-4 {
  background-color: rgb(255, 255, 255);
  width: 550px;
}

.widget-total-saving:not(:last-child) {
  margin-right: 20px;
}

.widget-type-4 .widgetBoxShadow {
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

.widget-type-4 .listing-widget-list-wrapper {
  height: 84%;
  overflow: scroll;
}

.widget-wrapper-5 {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
}

.widget-type-5 {
  background-color: rgb(255, 255, 255);
  width: 550px;
}

.widget-type-5 .widgetBoxShadow {
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

.widget-type-5 .listing-widget-list-wrapper {
  height: 84%;
  overflow: scroll;
}

.widget-wrapper-6 {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
}

.widget-type-6 .widgetBoxShadow {
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

.widget-type-6 {
  background-color: rgb(255, 255, 255);
  width: 550px;
}

/* .widget-title-wrapper {
  background-color: #F8F8F8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 60px;
  padding: 15px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
} */
/* .widget-title-style {
  font-size: 18px;
  font-weight: 500;
  color: #101828;
  font-family: 'Manrope-Regular';
} */
/* #SMS_MESSAGING,#PATIENT_APP_MESSAGING,#CRM_ACTIVITY{
  width: 33%;
  margin-right: 8px;
} */
/* #VOICE_CALLING_DIRECTION_WISE,#USER_MESSAGING {
  width: 32%;
  margin-right: 8px;
} */
#SMS_MESSAGING .widgetBoxShadow,
#PATIENT_APP_MESSAGING .widgetBoxShadow,
#VOICE_CALLING_DIRECTION_WISE .widgetBoxShadow,
#APPOINTMENT_BOOKED .widgetBoxShadow,
#VIDEO_CALLING .widgetBoxShadow,
#USER_MESSAGING .widgetBoxShadow,
#APPOINTMENT_RATING .widgetBoxShadow,
#CRM_ACTIVITY .widgetBoxShadow {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

/* #ED_UTILIZATION_AMOUNT {
  width: 68%;
  margin-right: 8px;
} */

#ED_UTILIZATION_AMOUNT .widgetBoxShadow {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}


/* #OUT_OFFICE_HOUR_USER_ENGAGEMENT {
  width: 32%;
  margin-right: 8px;
} */



#OUT_OFFICE_HOUR_USER_ENGAGEMENT .widgetBoxShadow {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

/* #ENGAGEMENT_ON_APPLIED_RULES {
  width: 99%;
  margin-right: 8px;
} */



#ENGAGEMENT_ON_APPLIED_RULES .widgetBoxShadow {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
  background-color: #fff;
}

#PRE_DPC_VS_POST_DPC .widgetBoxShadow {
  width: 100%;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 425px;
  max-height: 425px;
  height: 425px;
  background-color: #fff;
}

#ALL_CLAIM_DATA {
  width: 99%;
  margin-right: 8px;
}


#ALL_CLAIM_DATA .widgetBoxShadow {
  width: 100%;
  border-width: 1px;
  margin-right: 12px;
  margin-bottom: 20px;
  min-height: 390px;
  max-height: 390px;
  height: 390px;
}

/* @media screen and (max-width: 1250px) {
   #SMS_MESSAGING, #PATIENT_APP_MESSAGING, #VOICE_CALLING_DIRECTION_WISE, #USER_MESSAGING, #CRM_ACTIVITY{
    width: 48%;
  }

  #ED_UTILIZATION_AMOUNT {
    width: 96%;
  }

  #ED_UTILIZATION_AMOUNT .widgetBoxShadow,
  #SMS_MESSAGING .widgetBoxShadow,
  #PATIENT_APP_MESSAGING .widgetBoxShadow,
  #VOICE_CALLING_DIRECTION_WISE .widgetBoxShadow,
  #USER_MESSAGING .widgetBoxShadow,
  #OUT_OFFICE_HOUR_USER_ENGAGEMENT .widgetBoxShadow,
  #CRM_ACTIVITY .widgetBoxShadow,
  #ALL_CLAIM_DATA .widgetBoxShadow,
  #ENGAGEMENT_ON_APPLIED_RULES .widgetBoxShadow {
    width: 100%;
    margin-right: 8px;
    background-color: #fff;
  }

  #OUT_OFFICE_HOUR_USER_ENGAGEMENT {
    width: 48%;
  }

  #ENGAGEMENT_ON_APPLIED_RULES {
    width: 48%;
  }
} */
#ED_UTILIZATION_AMOUNT.in-print-drawer {
  width: 100%;
  margin-right: 8px;
  min-width: 100%;
}

#OUT_OFFICE_HOUR_USER_ENGAGEMENT.in-print-drawer {
  width: 100%;
  margin-right: 8px;
  min-width: 100%;
}

#ENGAGEMENT_ON_APPLIED_RULES.in-print-drawer {
  width: 100%;
  margin-right: 8px;
  min-width: 100%;
}

#PRE_DPC_VS_POST_DPC.in-print-drawer {
  width: 100%;
  margin-right: 8px;
  min-width: 100%;
}

#ALL_CLAIM_DATA.in-print-drawer {
  width: 100%;
  margin-right: 8px;
  min-width: 100%;
}

#SMS_MESSAGING.in-print-drawer,
#PATIENT_APP_MESSAGING.in-print-drawer,
#VOICE_CALLING_DIRECTION_WISE.in-print-drawer,
#APPOINTMENT_BOOKED.in-print-drawer,
#VIDEO_CALLING.in-print-drawer,
#USER_MESSAGING.in-print-drawer,
#APPOINTMENT_RATING.in-print-drawer,
#CRM_ACTIVITY.in-print-drawer {
  margin-right: 8px;
  width: 48%;
}

/*
@media screen and (max-width: 1800px) {
  #SMS_MESSAGING.in-print-drawer, #PATIENT_APP_MESSAGING.in-print-drawer, #VOICE_CALLING_DIRECTION_WISE.in-print-drawer, #USER_MESSAGING.in-print-drawer, #CRM_ACTIVITY.in-print-drawer{
  margin-right: 8px;
  width: 48%;
} */
/* @media screen and (max-width: 1800px) {

  .card-widget-type-1,
  .widget-type-3,
  .widget-type-4,
  .widget-type-5 .widget-type-6 {
    width: 500px;
  }
} */

.card-widget-type:not(.SAVINGS.card-widget-type) {
  width: 32%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}

#ED_UTILIZATION_AMOUNT.card-widget-type {
  width: 65%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}

#ENGAGEMENT_ON_APPLIED_RULES.card-widget-type {
  width: 100%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}

#PRE_DPC_VS_POST_DPC.card-widget-type {
  width: 100%;
  margin-right: 8px;
  margin-right: 8px;
  height: 435px;
}



.card-widget-type .widgetBoxShadow:not(.SAVINGS.card-widget-type .widgetBoxShadow) {
  height: 390px;
}

.in-print-drawer.card-widget-type:not(.SAVINGS.card-widget-type) {
  width: 48%;
  margin-right: 8px;
  margin-right: 8px;
  height: 400px;
}

#ED_UTILIZATION_AMOUNT.card-widget-type.in-print-drawer {
  width: 100%;
}

#NET_PROMOTER_SCORE,
#COMMUNICATION_STATS,
#ENROLLED_FOR_CARE,
#CUSTOMER_SATISFACTION,
#APPOINTMENT_BY_TIME,
#TELEPHONE_BY_TIME,
#APPOINTMENT_TYPE,
#EMPLOYEE_VS_DEPENDANT_VISITS,
#VIDEO_CALLING,
#APPOINTMENT_BY_DAY,
#CRM_ACTIVITY,
#OUT_OFFICE_HOUR_USER_ENGAGEMENT,
#APPOINTMENT_SELF_SCHEDULED {
  width: 48%;
}

#GROUP_SAVING.widget-total-saving {
  width: 68%;
}

#TOTAL_SAVINGS.widget-total-saving {
  width: 30%;
  height: 447px;
}


#SAVINGS-wrapper.widget-wrapper-3.in-print-drawer-wrapper {
  flex-direction: column;
}

#TOTAL_SAVINGS.widget-total-saving.in-print-drawer,
#GROUP_SAVING.widget-total-saving.in-print-drawer,
#NET_PROMOTER_SCORE.in-print-drawer,
#COMMUNICATION_STATS.in-print-drawer,
#ENROLLED_FOR_CARE.in-print-drawer,
#CRM_ACTIVITY.in-print-drawer,
#OUT_OFFICE_HOUR_USER_ENGAGEMENT.in-print-drawer,
#CUSTOMER_SATISFACTION.in-print-drawer,
#APPOINTMENT_BY_TIME.in-print-drawer,
#TELEPHONE_BY_TIME.in-print-drawer,
#APPOINTMENT_TYPE.in-print-drawer,
#EMPLOYEE_VS_DEPENDANT_VISITS.in-print-drawer,
#VIDEO_CALLING.in-print-drawer,
#APPOINTMENT_BY_DAY.in-print-drawer,
#APPOINTMENT_SELF_SCHEDULED.in-print-drawer {
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
}

.saving-group-item {
  width: auto;
}

.card-widget-type.card-widget-type-2 {
  height: auto !important;
}

.card-widget-type.card-widget-type-2 .widgetBoxShadow {
  height: auto !important;
}


#NET_PROMOTER_SCORE .widgetBoxShadow,
#COMMUNICATION_STATS .widgetBoxShadow,
#ENROLLED_FOR_CARE .widgetBoxShadow,
#CUSTOMER_SATISFACTION .widgetBoxShadow,
#APPOINTMENT_BY_TIME .widgetBoxShadow,
#TELEPHONE_BY_TIME .widgetBoxShadow,
#APPOINTMENT_TYPE .widgetBoxShadow,
#EMPLOYEE_VS_DEPENDANT_VISITS.widgetBoxShadow,
#VIDEO_CALLING.widgetBoxShadow,
#APPOINTMENT_BY_DAY.widgetBoxShadow,
#APPOINTMENT_SELF_SCHEDULED .widgetBoxShadow{
  margin-bottom: 10px;
}

#NET_PROMOTER_SCORE .widgetBoxShadow .score-detail-item {
  width: 47%;
}

.cover-page-view {
  position: relative;
  background-size: cover;
  background-position: center;
  background-position-x: right;
  height: 90.5vh;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1340px) {
  #SAVINGS-wrapper.widget-wrapper-3 {
    flex-direction: column;
  }


  #TOTAL_SAVINGS.widget-total-saving,
  #GROUP_SAVING.widget-total-saving,
  #NET_PROMOTER_SCORE,
  #COMMUNICATION_STATS,
  #ENROLLED_FOR_CARE,
  #CUSTOMER_SATISFACTION,
  #APPOINTMENT_BY_TIME,
  #TELEPHONE_BY_TIME,
  #APPOINTMENT_TYPE,
  #EMPLOYEE_VS_DEPENDANT_VISITS,
  #VIDEO_CALLING,
  #APPOINTMENT_BY_DAY,
  #APPOINTMENT_SELF_SCHEDULED,
  #CRM_ACTIVITY {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .card-widget-type:not(.SAVINGS.card-widget-type) {
    width: 48%;
    margin-right: 8px;
    margin-right: 8px;
    height: 400px;
  }

  #ED_UTILIZATION_AMOUNT.card-widget-type {
    width: 100%;
  }
}

.location-selector .ant-select-selector {
  color: #825AC7 !important;
  background-color: transparent !important;
}
.uploaded-print-logo .uploaded-print-edit-logo {
  display: none;
}
.uploaded-print-logo:hover .uploaded-print-edit-logo {
  display: flex;
}
.cover-page-edit .ant-popover-inner-content {
  padding: 0;
}
textarea.cover-page-title {
  font-size: 40px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 48px;
  padding: 8px;
}
textarea.cover-page-description {
  padding: 8px;
  font-weight: 600;
  line-height: 1.5em;
  height: 200px;
}
textarea.cover-page-title::placeholder, textarea.cover-page-description::placeholder {
  color: #fff;
}

@media print {
  .cover-page-view {
    background-size: cover;
    background-position: 'center';
    background-position-x: right;
    height: 100vh;
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
