.task-filters {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}
.task-filters::-webkit-scrollbar {
  display: none;
}
.task-filters-wrap {
  flex-wrap: wrap;
}
