.tab-container {
  margin: 2px 4px;
  border: 0.5px solid transparent;
  border-radius: 4px;
  border-color: transparent;
  background-color: transparent;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

.tab-container-selected {
  border-color: #d7c0ff;
  background-color: #fcfaff;
}

.tab-container:hover {
  background-color: #FCFAFF;
  color: #000;
}
