.modal-container {
  position: fixed;
  width: 290px;
  height: 56px;
  left: 42%;
  top: 89%;
  z-index: 9999;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
}

.sidecar-modal-container {
  position: fixed;
  width: 290px;
  height: 56px;
  left: 27%;
  top: 89%;
  z-index: 9999;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 8px;
  /* padding: 8px; */
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.modal-container-communicationLog{
  position: fixed;
  width: 400px;
  height: 75px;
  left: 67%;
  top: 85%;
  z-index: 9999;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}