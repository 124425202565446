.llmModalV1 .ant-modal-body{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 20 !important;
    padding-right: 20 !important;
  }
  .llmModalV1 .ant-modal-footer{
    padding-bottom: 20px !important;
    padding-top: 16px !important;
  }
  .llmModalV1 .ant-modal-header{
    padding-top: 20px !important;
    padding-bottom: 8px !important;
  }
  .llmModalV1 .btn{
    border-radius: 4px !important;
  }
  