.employer-drill-down-table .ant-table-body .ant-table-cell {
  position: relative;
  height: 150px;
}

.employer-drill-down-table .ant-table-body .ant-table-cell .employer-drill-down-cell {
  position: absolute;
  top: 10px;
}

.employer-drill-down-table .ant-table-body .ant-table-cell .CHRONIC_CONDITIONS {
  width: 150px;
}
.employer-drill-down-table .ant-table-tbody > tr.ant-table-row-selected > td  {
  background: #ffffff;
}
