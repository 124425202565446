.conversation-list-item-container-f1 {
  flex: 1;
}

.conversation-list-item-container-f09 {
  flex: 0.9;
}

.load-more-conversations {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.load-more-conversations-text {
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: underline;
  color: #1a0647;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 14px;
}
