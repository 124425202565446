addEDitUserMultiSelctect
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
}

.ant-layout-content.addEDitUserMultiSelect
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  /* height: auto; */
}

.editUserWithBusinessHoursSelect .ant-select-selector {
  padding-left: 8px !important;
}
.editUserWithBusinessHours.ant-select-item.ant-select-item-option.ant-select-item-option-active{
  background-color: #fff !important;
}