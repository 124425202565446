.select-employer .ant-select-selection-item {
  margin-top: 10px;
}


.select-employer .ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: '#E4E7EC' !important;
  background-color: #f9fafb;
  height: 40px ;
}

.select-employer .ant-select-selection-search-input {
  height: 40px !important;
}

.select-employer .ant-select-selection-item {
  margin-top: 0px !important;
}



.select-payer .ant-select-selection-item {
  margin-top: 10px;
}


.select-payer .ant-select-selector {
  border-radius: 8px;
  box-shadow: none;
  border-color: '#E4E7EC' !important;
  background-color: #f9fafb;
  height: 40px ;
}

.select-payer .ant-select-selection-search-input {
  height: 40px !important;
}

.select-payer .ant-select-selection-item {
  margin-top: 0px !important;
}
