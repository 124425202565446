.automation_table > .ant-table-thead {
  font-size: 14px !important;
}
.ant-table-thead > tr > th {
  color: #667085;
  background: #f9fafb !important;

  font-size: 14px !important;
}

.automationModalContainer .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #f9f5ff;
}

.ant-select-selection-item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
