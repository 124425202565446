.date-range-analytics .ant-picker-content .ant-picker-cell::before {
 position: absolute;
 right: 0;
 left: 0;
 z-index: 1;
 transform: translateY(-50%);
 transition: all 0.3s;
 content: '';
 width: 100%;
 height: 100%;
 background: none;
 background-color: none;
}