.measureCriteriaDetailDrawer .ant-drawer-content {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.measureCriteriaDetailDrawer .ant-drawer-header {
  padding: 0px;
}

.measureCriteriaDetailDrawer .ant-drawer-body {
  padding: 0px
}

.measure-criteria-detail-table .ant-table-thead > tr > th {
  padding-left: 16px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 3px;
}

.measure-criteria-detail-table .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: unset !important;
}

.measure-criteria-detail-table .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 0 0 0 #fafafa;
}

.measure-criteria-detail-table .ant-table-tbody > tr > td, .measure-criteria-detail-table .ant-table tfoot > tr > th, .measure-criteria-detail-table .ant-table tfoot > tr > td {
  padding-top: 8px;
  padding-bottom: 8px;
  border-width: 0px;
  cursor: auto;
}

tr.measure-criteria-detail > td {
  border-bottom: 0.5px solid #E9ECF1 !important;
}
.measure-criteria-detail-table tr.ant-table-expanded-row > td {
  background-color: #F6F7F8;
  margin: 0px;
  padding: 0px;
}

tr.measure-criteria-detail-last-row > td {
  border-bottom: 0px;
}

.measure-criteria-detail-table .ant-table-thead > tr > th {
  border-width: 0.5px;
  border-color: #D0D6E1;
}

.measure-criteria-detail-table .ant-table-body::-webkit-scrollbar {
  display: none;
}