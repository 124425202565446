.conversation-sub-tab {
  display: flex;
  flex: 0.5;
  background-color: #f9fafb;
  border-width: 0px;
  padding: 4px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0px solid transparent
}

.conversation-sub-tab-selected {
  border: 0.5px solid #ced4dd;
  background-color: #ffffff;
}


.tooltip-pressable {
  padding: 4px;
  justify-content: center;
  margin: 0px 2px
}

.filter-icon-pressable {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  justify-content: center;
  margin: 0px 2px;
  align-items: center;
  padding: 0px 8px;
}

.tags-pressable {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  justify-content: center;
  margin: 0px 2px;
  align-items: center;
  padding: 0px 8px;
}
