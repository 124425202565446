.typeRadio .ant-radio-checked .ant-radio-inner{
    border-color: #1a0647 !important ;
}

.typeRadio .ant-radio-checked .ant-radio-inner:after{
    background-color: #1a0647;
}

.typeRadio .ant-radio:hover .ant-radio-inner {
    border-color: #1a0647 ;
}

.custom-select-styles .ant-select-selector {
    border-radius: 4px !important;
}