.container {
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0px;
  position: relative;
  z-index: 0;
}

.textContainer {
  height: 32px;
  border-radius: 4px;
  margin: 4px 0;
  border-width: 0;
  border-color: transparent;
  display: flex;
  background-color: white;
  align-items: center;
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #6f7a90;
  margin-left: 18px;
}

.selectedTextContainer {
  border: 0.5px solid #c4c4c4;
  background-color: #fcfaff;
}

.selectedText {
  color: #9747ff;
}

.textContainer:hover {
  background-color: #F9FAFB;
}

.chip {
  width: 2px;
  height: 16px;
  gap: 0px;
  border-radius: 0px 2px 2px 0px;
  opacity: 0px;
  background-color: transparent;
}

.chipSelected {
  background-color: #D7C0FF;
}