.display_card_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 524px;

  /* Single Tone/white */

  background: #ffffff;
  /* Gray/gray.200 */

  border: 1px solid #d0d0d0;
  /* Shadow/sm */

  /* box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06); */
  border-radius: 8px;
}

.display_card_container > header {
  width: 100%;
  padding: 20px;
}
.display_card_container > main {
  width: 100%;
}

.display_card_container > .display_card_title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.display_card_container > .display_card_subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #98a2b3;
}
