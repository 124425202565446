.task-tool-tip .ant-tooltip-arrow-content::before {
  background-color: #fff !important;
  background: #fff !important;
  z-index: -9999;
  }
.task-tool-tip .ant-tooltip-placement-bottom {
  background-color: transparent !important;
}
.task-tool-tip .ant-tooltip-arrow-content {
  margin-left: 20px;
  z-index: -111;
}