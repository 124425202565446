.responsive-tag-render {
  .ant-select-selection-item-content {
    padding-top: 2px !important;
    padding-right: 8px !important;
    padding-bottom: 2px !important;
    padding-left: 10px !important;
    background-color: #F2F4F7 !important;
    border-radius: 16px !important;
    height: 32px !important;
    font-weight: 50 !important;
    align-items: center !important;;
    justify-content: center !important;;
    display: flex;
    color: black !important;
  }

  .ant-select-disabled,
  .ant-select-selector,
  .ant-select-selector input,
  .ant-select-selection-item {
    cursor: default !important;
    background-color: white !important;
    border-radius: 0 !important;
    border-color: white !important;
    color: black !important;
  }
  .ant-select-selection-item {
    padding: 0 !important;
  }
  .ant-select :hover {
    border-color: white !important;
  }
  .ant-select-selector {
    padding: 4px !important;
  }
}
