.main-side-bar {
  height: 92.6vh;
}

.sideBarMenu span.ant-menu-title-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.leftoverlaypopup {
  left: 85px !important;
  padding-left: 0px;
}

.link-visited:hover{
  text-decoration: none !important;
}