.lab-vital-tracking-tab:hover {
  border-radius: var(--4, 4px);
  color: #4f5052 !important;
  background: var(--colors-monochrome-black-5, rgba(22, 24, 29, 0.05));
  box-shadow: 0px 12px 60px -15px rgba(0, 0, 0, 0.06);

}

.lab-vital-tracking-tab.selected {
  border-radius: var(--4, 4px);
  border: 0.5px solid var(--Colors-Grey-100, #CED4DD);
  background: var(--Colors-Monochrome-White, #FFF);
  
  /* Dropdown Shadow */
  box-shadow: 0px 12px 60px -15px rgba(0, 0, 0, 0.06);

}


.custom-lab-track-collapse .ant-collapse-content.ant-collapse-content-active {
  border: none !important;
}

.custom-lab-track-collapse .ant-divider.ant-divider-horizontal {
  border: none !important;
}
.custom-lab-track-collapse .ant-collapse-content > .ant-collapse-content-box {
  /* margin: 50px; */
  /* background: #F6F7F8; */
  margin: 10px;
  border-radius: var(--8, 8px);
}


.custom-lab-track-collapse .ant-collapse-header {
  align-items: center !important;
}


.lab-vital-tracking-tab :hover {
  
  color: rgba(52, 64, 84, 1) !important;
  
}

