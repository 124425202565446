.progress-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: visible;
}

.progress-fill {
  height: 100%;
  background-color: #6bd98f;
  transition: width 0.3s ease;
  border-radius: 4px;
}

.progress-arrow-wrapper {
  position: absolute;
  height: 47px;
  overflow: visible;
}

.progress-arrow-text {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  bottom: 2px;
}

.progress-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.progress-info {
  width: 100%;
}

.leading-text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #8A94A8;
  margin-right: 3px;
}

.leading-percentage {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.percentage-text {
  font-size: 16px;
  font-weight: bold;
  color: #757575;
}
